import { tagOf } from "./misc-util";
export function hasError(element) {
    var _a;
    if (((_a = element === null || element === void 0 ? void 0 : element.errormessage) === null || _a === void 0 ? void 0 : _a.length) > 0 ||
        (element === null || element === void 0 ? void 0 : element.internalerror) ||
        (element === null || element === void 0 ? void 0 : element.error)) {
        return true;
    }
    //Select only direct children with slot="error-message"
    const slottedElement = element.querySelector(":scope > *[slot=error-message]");
    const elements = (slottedElement === null || slottedElement === void 0 ? void 0 : slottedElement.assignedElements)
        ? slottedElement === null || slottedElement === void 0 ? void 0 : slottedElement.assignedElements()
        : [slottedElement];
    return elements.some((element) => {
        if (tagOf(element) === "dx-error-message") {
            return !element.hidden && element.textContent.length > 0;
        }
        return false;
    });
}
